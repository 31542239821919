<template>
  <div class="grid place-items-center p-10">
    <img :src="require('@/assets/images/logo.svg')" class="w-1/2 pt-8" />
    <lottie-animation
      path="lotties/maintenance.json"
      :loop="true"
      :autoPlay="true"
      :speed="1"
      :height="320"
    />
    <p class="text-xl text-center font-ZuumeRegular">
      Sorry, we're down for a scheduled maintenance. Please check back in
      sometime.
    </p>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "Maintenance",
  components: { LottieAnimation },
  mounted() {
    ApiService.get(apiResource.checkBusinessSlug, {
      slug: this.$store.getters.businessSlug,
    }).then((data) => {
      if (!data.data.errorcode) {
        this.$router.push({
          name: "Lobby",
          params: { business: this.$store.getters.businessSlug },
        });
      }
    });
  },
};
</script>

<style scoped></style>
